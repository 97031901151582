@import 'assets/scss/main.scss';

.industryContainer{
    @include screenPaddingTop;
}

.industries_wrapper{
    margin: 1em 0em !important;
    background-image: url('../../../assets/images/insights/bannerBg.webp') !important;
    background-size: 100% 100%;
    @include for_media(mobileScreen){
        margin: 0em !important;
        padding: 4em 0em !important;
    }
}

.industry_header {
    font-weight: 700;
    line-height: 57.6px;
    font-size: 48px;
    text-align: left;
    @include for_media(mobileScreen){
        font-size: 32px !important;
        line-height: normal;
    }
}

.description {
    font-weight: 500;
    line-height: 28.8px;
    font-size: 20px;
    text-align: left;
    @include for_media(mobileScreen){
        font-size: 18px !important;
        line-height: normal;
    }
}

.leftBanner{
    padding: 0 5% !important;
}

.rightBanner {
    padding: 0 !important;
  width: 100%;
  height: 100% !important;
}

.subContainer{
    margin: 5em 0em !important;
}

img{
    margin: auto;
}

.coverSection{
    background-image: url('../../../assets/images/insights/coverBg.webp');
    background-size: 100% 100%;
    padding: 2em !important;
    margin: 3em !important;
    @include for_media(mobileScreen){
        margin: 1em !important;
        padding: 3em 1em !important;
    }
}
.articleContainer{
    width: 100% !important;
    padding: 2em 10% !important;
    @include for_media(mobileScreen){
        padding: 1em !important;
    }
    .articleCard{
        cursor: pointer;
        margin: 1em 1em !important;
        text-align: center;
        width: 80% !important;
        @include for_media(mobileScreen){
            width: 90% !important;
        }
    }
}

.nextarrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    right: -8%;
    width: 60px;
    @include for_media(mobileScreen){
        display: none;
    }
}

.prevarrow{
    position: absolute;
    top: 43%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    left: -8%;
    width: 60px;
    transform: scaleX(-1);
    @include for_media(mobileScreen){
        display: none;
    }
}