
/* Primary Font */

@font-face {
  font-family: "FigTree";
  src: local("Fig_Tree_Regular"),
    url("../fonts/Figtree-Regular.ttf") format("truetype");
  font-weight: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "FigTree";
  src: local("Fig_Tree_Bold"),
    url("../fonts/Figtree-Bold.ttf") format("truetype");
  font-weight: bold;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "FigTree";
  src: local("Fig_Tree_SemiBold"),
    url("../fonts/Figtree-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-weight: 600;
  font-display: swap;
}