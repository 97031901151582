@import 'assets/scss/main.scss';

.industryContainer{
    @include screenPaddingTop;
}

.industries_wrapper{
    margin: 1em 0em !important;
    background-image: url('../../../assets/images/research/research_left_banner.webp') !important;
    background-size: 100% 100%;
    @include for_media(mobileScreen){
        margin: 0em !important;
        padding: 4em 0em !important;
    }
}

.industry_header {
    font-weight: 700;
    line-height: 57.6px;
    font-size: 48px;
    text-align: left;
    @include for_media(mobileScreen){
        font-size: 32px !important;
        line-height: normal !important;
    }
}

.description {
    font-weight: 500;
    line-height: 28.8px;
    font-size: 20px;
    text-align: left;
    @include for_media(mobileScreen){
        font-size: 18px !important;
        line-height: normal !important;
    }
}

.leftBanner{
    padding: 0 5% !important;
}

.rightBanner {
    padding: 0 !important;
  width: 100%;
  height: 100% !important;
}

.subContainer{
    margin: 5em 0em !important;
}