@import "assets/scss/main.scss";

.leadersImg {
    display: flex;
    justify-content: space-evenly;
}

.aboutusContainer {
    @include screenPaddingTop;
    margin-top: 1em !important;
    @include for_media(mobileScreen){
        margin: 0em !important;
    }
    .service_wrapper {
        background-image: url('../../../assets/images/about/bannerBg.webp');
        background-size: 100% 100%;
        @include for_media(mobileScreen){
            padding: 3em 0em !important;
            background-size: cover;
        }
        .industry_header {
            font-weight: 700;
            line-height: 57.6px;
            font-size: 48px;
            text-align: left;
            @include for_media(mobileScreen){
               font-size: 32px;
               line-height: normal !important;
            }
        }
        
        .description {
            font-weight: 500;
            line-height: 28.8px;
            font-size: 20px;
            text-align: left;
            @include for_media(mobileScreen){
                font-size: 18px;
                line-height: normal !important;
             }
        }
        
        .leftBanner{
            padding: 0 5% !important;
        }
        
        .rightBanner {
            padding: 0 !important;
          width: 100%;
          height: 100% !important;
        }
    }
}

.aboutUsCoreValues {
    background-image: url('../../../assets/images/insights/coverBg.webp');
    background-size: 100% 100%;
    padding: 3em 6em !important;
    margin: 4em 10em !important;
    @include for_media(mobileScreen){
        margin: 4em 1em !important;
        padding: 1em !important;
    }
    h1 {
        margin-bottom: 1em;
    }
}

.aboutUsMissionVision {
    background-image: url('../../../assets/images/insights/coverBg.webp');
    background-size: 100% 100%;
    padding: 2em !important;
    margin: 3em auto !important;
    width: 40% !important;
    @include for_media(mobileScreen){
        margin: 1em !important;
        width: 100% !important;
        padding: 1em !important;
    }
}

.contactUsImg {
    display: flex;
    justify-content: space-evenly;
}

.contactUsMapBtn {
    margin: 1em auto !important;
    padding: 1em !important;
    color: $black !important;
    font-size: 1.2em !important;
    border-radius: 2em !important;
    box-shadow: 0px 4px 12px 0px #000000 !important;
}

.contactUs {
    background-image: url(http://localhost:3000/static/media/coverBg.f1fc62a99d4aad56411b.webp);
    background-size: 100% 100%;
    padding: 2em !important;
    margin: 2em 6em !important;
    @include for_media(mobileScreen){
        margin: 1em !important;
        padding: 1em !important;
    }
}

.wrappper {
    padding: 2em;
    margin: 2em 6em 2em 6em;
    text-align: center;
    @include for_media(mobileScreen){
        margin: 2em 1em !important;
    }
}

.cardContent {
    border-radius: 10px !important;
    background-color: #D9D9D9 !important;
    text-align: center !important;
    .buttonTxt {
        width: 100% !important;
        margin: 1em auto !important;
        width: max-content !important;
    }
    .content{
        padding: 1.5em !important;
    }
    .desc{
        min-height: 40px;
    }
}