@import "assets/scss/main.scss";

h1 {
  text-align: center;
  font-size: 36px !important;
  font-weight: 700;
  color: $black;
  @include for_media(mobileScreen){
    font-size: 32px !important;
    line-height: normal !important;
}
}

h2 {
  text-align: center;
  font-size: 32px !important;
  color: $black;
  font-weight: 600;
  @include for_media(mobileScreen){
    font-size: 28px !important;
    line-height: normal !important;
}
}

h3 {
  font-size: 28px !important;
  text-align: center;
  font-weight: bold;
}

h4 {
  font-size: 22px !important;
}

p {
  text-align: center;
  font-size: 20px;
  color: $black;
}

.page_wrapper {
  position: relative;
  padding: 2em;
  margin: 2em 6em 2em 6em;
  text-align: center;
  opacity: 0;
  transform: scale(1.2);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  .robot1{
    position: absolute !important;
    top: -15% !important;
    left: 0 !important;
    width: 120px;
    @include for_media(mobileScreen){
      display: none !important;
    }
  }
  .robot2{
    position: absolute !important;
    bottom: -5% !important;
    right: 0 !important;
    width: 120px;
    @include for_media(mobileScreen){
      display: none !important;
    }
  }
  @include for_media(mobileScreen){
    margin: 1em;
  }
}

.page_wrapper.visible {
  opacity: 1;
  transform: scale(1);
}

.caseStudy{
  width: 80% !important;
  margin: auto;
  @include for_media(mobileScreen){
    width: 100% !important;
  }
}

.oddBackground {
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 1em;
  opacity: 0;
  transform: scale(1.2);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  @include for_media(mobileScreen){
    background-image: url('../../assets/images/pageTemplateMob.webp') !important;
  }
}

.odd.visible {
  opacity: 1;
  transform: scale(1);
}

.pageWrapperBg1 {
  background-image: url('../../assets/images/pageTemplateBg1.webp');
}

.pageWrapperBg2 {
  background-image: url('../../assets/images/pageTemplateBg2.webp');
}

.pageWrapperBg3 {
  background-image: url('../../assets/images/pageTemplateBg3.webp');
}

.pageWrapperBg4 {
  background-image: url('../../assets/images/pageTemplateBg4.webp');                  
}

.pageWrapperBg5 {
  background-image: url('../../assets/images/pageTemplateBg5.webp');                  
}

.pageWrapperBg6 {
  background-image: url('../../assets/images/pageTemplateBg6.webp');                  
}

.pageWrapperBg7 {
  background-image: url('../../assets/images/pageTemplateBg7.webp');                  
}

.pageWrapperBg8 {
  background-image: url('../../assets/images/pageTemplateBg8.webp');                  
}

.pageWrapperBg9 {
  background-image: url('../../assets/images/pageTemplateBg9.webp');                  
}

.pageWrapperBg10 {
  background-image: url('../../assets/images/pageTemplateBg10.webp');                  
}

.column_wrapper {
  img{
    margin: 2em auto;
    min-height: 30px;
    @include for_media(mobileScreen){
      margin: 1em auto;
    }
  }
  h3 {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 1em;
  }

  p {
    font-size: 1.1em;
    color: $black;
    min-height: 85px !important;
    @include for_media(mobileScreen){
      min-height: max-content !important;
    }
  }
}


.btn_wrapper {
  margin: 2em 0em !important;
}
button{
  line-height: normal !important;
}