@import './config.scss';

body{
  font-family: $primaryFontName !important;
}
h1 {
  font-family: $secondaryFontName !important;
  font-size: 4em;
  font-weight: 700;
}
h2 {
  font-family: $secondaryFontName !important;
  font-size: 3.2em;
  font-weight: 700;
}
h3{
  font-family: $secondaryFontName !important;
  font-size: 2.5em;
  font-weight: 700;
}
h4{
  font-family: $primaryFontName !important;
  font-size: 1.9em ;
  font-weight: 600;
}
h5{
  font-family: $primaryFontName !important;
  font-size: 1em;
  font-weight: 600;
}
h6{
  font-family: $primaryFontName !important;
  font-size: 0.7em;
  font-weight: 600;
}
p{
  font-family: $primaryFontName !important;
  font-size: 16px;
}
.button {
  font-family: $primaryFontName !important;
  font-size: 1.1em;
  border-radius: 2em;
}

.silverBgbutton{
  padding: 15px 30px !important;
  color: $black !important;
  border-radius: 2em !important;
  font-size: 1.2em !important;
  background: url('../images/buttonBg.webp') !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  border-radius: 1.5em !important;
  box-shadow: 0px 4px 12px 0px #000000 !important;
}

.whiteBgbutton{
  padding: 15px 30px !important;
  color: $black !important;
  border-radius: 2em !important;
  font-size: 1.2em !important;
  background: white !important;
  border-radius: 1.5em !important;
  box-shadow: 0px 4px 12px 0px #000000 !important;
}

.clr20{
  height: 20px;
  clear: both;
}

@mixin screenPaddingTop {
  padding-top: 6.5em;

  @include for_media(mobileScreen) {
    padding-top: 0;
  }
}

@mixin for_media($breakpoints) {
  $conditions : ();
  @each $breakpoint in $breakpoints {
      // If the key exists in the map
      $conditions: append(
          $conditions,
          #{inspect(map-get($media_queries, $breakpoint))},
          comma
      );
  }

  @media #{$conditions} {
      @content;
  }
}