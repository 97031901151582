@import "assets/scss/main.scss";

.btn_container {
    display: flex !important;
    justify-content: space-around !important;
    margin: 2em 0;
    align-items: center;
    @include for_media(mobileScreen){
        flex-direction: column;
        gap: 2em;
        margin: 1em;
    }
}

.white_btn_container {
    display: flex;
    justify-content: space-evenly;
    margin: 3em;
}

.careerContainer {
    @include screenPaddingTop;
    margin: 1em 0em !important;
}

.careers_wrapper {
    background-image: url('../../../assets/images/careers/careers_left_banner.webp') !important;
    background-size: 100% 100%;
    @include for_media(mobileScreen){
        padding: 4em 0em !important;
    }
}

.careers_header {
    font-weight: 700;
    line-height: 57.6px;
    font-size: 48px;
    text-align: left;
    padding: 0 5% !important;
    @include for_media(mobileScreen){
        font-size: 32px !important;
        line-height: normal;
    }
}

.description {
    font-weight: 500;
    line-height: 28.8px;
    font-size: 20px;
    text-align: left;
    padding: 0 5% !important;
    @include for_media(mobileScreen){
        font-size: 18px !important;
        line-height: normal;
    }
}

.careerInfromation {
    margin: 8em 3em 3em 3em;
}

.cardDetails {
    margin: 2em 0 0 0 !important;
}

.careersRightBanner {
    padding: 0 !important;
}

.cardContent {
    border-radius: 1.2em !important;
}

.careersJourney {
    margin: 5em 0;
}

.deepMagicLife {
    margin: 3em;
}

.journeyCard {
    padding: 1em 1em !important;
    border: 3px solid $borderColor1 !important;
    border-radius: 1em !important;
    .imgContainer{
       margin: auto !important;
    }
}

.careerBgContent{
    background-image: url('../../../assets/images/careers/careerBg.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 3em;
    @include for_media(mobileScreen){
        padding: 1em !important;
    }
}
