.iocLogoWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

  .iocLogo {
    position: absolute;
    left: 50%;
    top: 48.5%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
  .iocLogoLoader {
    animation: rotation 1s infinite both;
  }
  
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(359deg);
    }
  }
}

