@import "assets/scss/main.scss";

.footer {
  padding: 2em 6.5em 0em 6.5em !important;
  background-image: url('../../../../assets/images/footerBackground.webp');
  background-size: cover;
  height: 18.625em;
  margin: 2em 0 0 0 !important;
  color: $black;
  .footerDiv1 {
    .SchoolAge {
      display: flex;
      align-items: center;

      .ioacContent {
        font-size: 4.5em;
        font-family: $secondaryFontName;
        color: $white;
        margin-right: 0.3em;
        letter-spacing: 0.09em;
      }

      .logoImg {
        width: 5.2em;
      }
    }

    .part2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .ioacContent2 {
        font-weight: 600;
        font-size: 1.1em;
        margin-right: 1em;
      }

      .ioacLink {
        font-size: 0.8em;

        p {
          border-radius: 2em;
          width: 14em;
          height: 2.1em;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1em 0em;
          box-shadow: 0 8px 16px 0 $boxshadowcolor1,
            0 6px 20px 0 $boxshadowcolor1;
        }
      }
    }
  }

  .footerDiv2 {
    line-height: 1.7;

    .contact {
      font-weight: 600;
      font-size: 1.2em;
    }

    .contactDetails {
      .contactContent {
        color: $primaryColor !important;
        font-weight: 600;
        font-size: 1.2em;
      }
    }

    .clr15 {
      clear: both;
      height: 1.1em;
    }

    .socialMedia {
      font-weight: 600;
      font-size: 1.4em;
      line-height: 2;
    }

    .socialMediaLogo {
      display: flex;
      gap: 0.5em;
      margin: 0.5em 0em;
      justify-content: center;

      .logo {
        margin-right: 0.5em;
        width: 1.7em;
      }
    }
    span{
      font-size: 16px !important;
      font-weight: 700 !important;
    }
  }

  .footerDiv3 {
    display: flex;
    justify-content: space-around;

    .visitUs {
      font-weight: 600;
      font-size: 1.2em;
      line-height: 2;
    }

    .visitContent {
      font-weight: 500;
      width: 12em;
      font-size: 0.8em;
      line-height: 1.7;
      text-align: left;
    }

    img {
      width: 14em !important;
    }
  }
}

.footerMessage {
  background-color: #22505A;
  text-align: center;
  height: 4.375em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  color: $white;

  span {
    margin: 0em 0.5em;
  }
  .menu{
    cursor: pointer;
  }
  .menu:hover{
    font-weight: 900;
  }
}

/*************** Mobile View **********************/

@include for_media(mobileScreen) {
  .footer {
    height: auto !important;
    padding: 0em 1em !important;
    margin: 0 !important;

    .SchoolAge {
      justify-content: center !important;

      .ioacContent {
        font-size: 6em !important;
      }

      .logoImg {
        width: 6em !important;
      }
    }

    .part2 {
      justify-content: center !important;
      flex-direction: column;

      .ioacLink {
        font-size: 1.2em !important;
      }
    }

    .footerDiv3 {
      justify-content: space-between !important;
      .visitUs {
        line-height: 1.5 !important;

        p {
          width: 16em !important;
          font-size: 0.7em !important;
        }
      }
      img{
        width: 10em !important;
      }
    }

    .footerDiv2 {
      line-height: 4 !important;
      font-size: 0.8em !important;

      .contactDetails {
        line-height: 1.5 !important;
      }
    }

    .socialMedia {
      line-height: 3 !important;
    }

    .socialMediaLogo {
      width: 15em !important;
      justify-content: space-between;

      .logo {
        width: 3.7em !important;
      }
    }

    .footerMap {
      width: 8em !important;
      border-radius: 1em !important;
    }
  }

  .footerMessage {
    background-image: url("../../../../assets/images/footerbackgroundMob.svg") !important;
    justify-content: space-evenly;
    height: 4em !important;

    span {
      margin: 0;
      font-size: 0.8em !important;
    }
  }
}