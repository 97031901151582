@import "assets/scss/main.scss";

.homeContainer{
  @include screenPaddingTop;
  margin-top: 1em !important;
  @include for_media(mobileScreen){
    margin: 0 !important;
  }
  .bannerSection{
    background-image: url('../../../assets/images/home/banner.webp');
    background-size: 100% 100%;
    padding: 8em 0em 5em 0em !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    text-align: center;
    @include for_media(mobileScreen){
      padding: 2em 0em !important;
    }
    h1{
      font-size: 45px !important;
      text-shadow: 0px 0px 24px #FFFFFF;
      @include for_media(mobileScreen){
        font-size: 32px !important;
      }
    }
    h2{
      font-weight: 500 !important;
      font-size: 29px !important;
      @include for_media(mobileScreen){
        font-size: 24px !important;
      }
    }
    .buttonSection{
      margin: 4em 0em;
      width: 100% !important;
      display: flex !important;
      justify-content: space-evenly !important;
      @include for_media(mobileScreen){
       flex-direction: column;
       gap: 2em;
       width: max-content !important;
    }
    }
  }
}