@import 'assets/scss/main.scss';

.informationContent{
    margin: 3em 10em;
    @include for_media(mobileScreen){
        margin: 2em 1em
    }
}
.serviceContainer{
    @include screenPaddingTop;
}

.service_wrapper{
    margin: 1em 0em !important;
    background-image: url('../../../assets/images/service/banner.webp') !important;
    background-size: 100% 100%;
    @include for_media(mobileScreen){
        margin: 0em !important;
        padding: 5em 0em !important;
    }
}

.description{
    margin: 5em 10em;
    font-size: 16px;
    @include for_media(mobileScreen){
        margin: 2em 1em
    }
}

.cardContent{
    border-radius: 1.2em !important;
}